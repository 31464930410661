import React from "react";
import styled from "styled-components";

import { Text } from "src/components/Text";
import { BodyText } from "src/components/BodyText";

interface VisitBlockProps {
  title: string;
  subtitle?: string;
  quote?: string;
  description: string;
}

export const VisitBlock: React.FC<VisitBlockProps> = ({
  title,
  subtitle,
  quote,
  description
}) => (
  <VisitBlockWrapper>
    <Text as="h2" color="red">
      {title}
    </Text>
    {subtitle && (
      <Text as="h3">
        {subtitle}
      </Text>
    )}
    {quote && (
      <QuoteWrapper>
        <QuoteText as="span" color="red">
          ‘
          {quote}
          ’
        </QuoteText>
      </QuoteWrapper>
    )}
    {description && (
      <DescriptionWrapper>
        <ul>
          <BodyText text={description} indented />
        </ul>
      </DescriptionWrapper>
    )}
  </VisitBlockWrapper>
);

const VisitBlockWrapper = styled.div`
  align-self: stretch;
`;

const QuoteWrapper = styled.div`
  margin-top: 1.6rem;
`;

const QuoteText = styled(Text)`
  font-style: italic;
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

const DescriptionWrapper = styled.div`
  margin-top: 1.6rem;

  ul {
    list-style-type: initial;
  }

  li {
    margin-left: 2rem!important;
    text-indent: 0!important;
    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }
`;
