import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled, { css } from "styled-components";

import { Text } from "src/components/Text";
import { BodyText } from "src/components/BodyText";
import { getImageMetadata } from "src/utils/image";
import { PageContent, PageWidth } from "src/components/layout/PageContent";
import { breakpoint } from "src/utils/media-query";
import { Wysiwyg } from "src/components/Wysiwyg";

import { VisitBlock } from "./VisitBlock";
import { Quote } from "./Quote";

type GatsbyImage = {
  readonly localFile?: {
    readonly childImageSharp?: Pick<GatsbyTypes.ImageSharp, "gatsbyImageData">;
  }
};

type ContentBlock = GatsbyTypes.Maybe<(
  Omit<GatsbyTypes.StrapiVisitPage_content, "booking_url" | "page_width_image" | "image_left" | "image_right">
  & {
    readonly page_width_image?: GatsbyImage
    readonly image_left?: GatsbyImage
    readonly image_right?: GatsbyImage
  }
)>;

interface DynamicPageContentProps {
  content: readonly ContentBlock[];
  width?: PageWidth;
}

export const DynamicPageContent: React.FC<DynamicPageContentProps> = ({ content, width = "normal" }) => {
  const getComponentByContentType = React.useCallback((contentBlock: ContentBlock) => {
    const [ pageWidthImage, pageWidthImageAlt ] = getImageMetadata(contentBlock?.page_width_image);
    const [ leftImage, leftImageAlt ] = getImageMetadata(contentBlock?.image_left);
    const [ rightImage, rightImageAlt ] = getImageMetadata(contentBlock?.image_right);

    if (
      contentBlock?.strapi_component === "text.body-text" &&
      contentBlock.body_text
    ) {
      return (
        <Block key={contentBlock.id} width="normal">
          <Wrapper pad>
            <BodyText text={contentBlock.body_text} />
          </Wrapper>
        </Block>
      );
    } else if (
      contentBlock?.strapi_component === "image.image" &&
      pageWidthImage
    ) {
      return (
        <Block key={contentBlock.id} width="extra-wide">
          <Wrapper>
            <ImageAspectWrapper>
              <ImageWrapper>
                <GatsbyImage
                  image={pageWidthImage}
                  alt={pageWidthImageAlt}
                />
              </ImageWrapper>
            </ImageAspectWrapper>

            {contentBlock.caption && (
              <StyledCaptionWysiwyg content={contentBlock.caption} />
            )}
          </Wrapper>
        </Block>
      );
    } else if (
      contentBlock?.strapi_component === "image.double-image" &&
      leftImage && rightImage
    ) {
      return (
        <Block key={contentBlock.id} width="extra-wide">
          <Wrapper>
            <ImageAspectWrapper>
              <DoubleImageWrapper>
                <GatsbyImage
                  image={leftImage}
                  alt={leftImageAlt}
                />
                <GatsbyImage
                  image={rightImage}
                  alt={rightImageAlt}
                />
              </DoubleImageWrapper>
            </ImageAspectWrapper>

            {contentBlock.caption && (
              <StyledCaptionWysiwyg content={contentBlock.caption} />
            )}
          </Wrapper>
        </Block>
      );
    } else if (
      contentBlock?.strapi_component === "visit-block.visit-block" &&
      contentBlock.title
    ) {
      return (
        <Block key={contentBlock.id} width="normal">
          <Wrapper sectionHeading={!contentBlock.description} pad>
            <VisitBlock
              title={contentBlock.title}
              subtitle={contentBlock.subtitle}
              description={contentBlock.description || ""}
              quote={contentBlock.quote}
            />
          </Wrapper>
        </Block>
      );
    } else if (
      contentBlock?.strapi_component === "text.quote" &&
      contentBlock?.quote
    ) {
      return (
        <Block key={contentBlock.id} width="normal">
          <Wrapper>
            <Quote quote={contentBlock.quote} />
          </Wrapper>
        </Block>
      );
    } else if (
      contentBlock?.strapi_component === "text.notice" &&
      contentBlock?.notice_text
    ) {
      return (
        <Block key={contentBlock.id} width="normal">
          <Wrapper pad>
            <NoticeWrapper>
              <NoticeText as="span" color="red" firstLineCaps>
                Important Information
              </NoticeText>
              <NoticeText as="p" color="red">
                {contentBlock.notice_text}
              </NoticeText>
            </NoticeWrapper>
          </Wrapper>
        </Block>
      );
    }

    return null;
  }, []);

  return (
    <PageContent width={width}>
      {content.map(getComponentByContentType)}
    </PageContent>
  );
};

const Block = styled(PageContent).attrs({ pad: false })``;

const Wrapper = styled.div<{
  pad?: boolean;
  sectionHeading?: boolean;
}>`
  ${({ sectionHeading, pad }) => css`
    margin: 1rem 0;
    ${breakpoint("tabletLL")} {
      margin: 2rem 0;
    }
    ${pad && css`
      padding: 1rem;
      ${breakpoint("tabletLL")} {
        padding: 0;
      }
    `}
    ${sectionHeading && css`
      margin-bottom: 0!important;
      padding-bottom: 0!important;
    `}
  `}
`;

const ImageAspectWrapper = styled.div`
  position: relative;
  padding-top: 75%;

  ${breakpoint("tabletLL")} {
    padding-top: 60%;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 0 1rem;
`;

const DoubleImageWrapper = styled(ImageWrapper)`
  flex-direction: row;
  align-items: stretch;

  & > div {
    flex: 1;

    &:first-child {
      margin-right: 0.5rem;

      ${breakpoint("tabletLL")} {
        margin-right: 1rem;
      }
    }
  }
`;

const NoticeWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  padding-top: 2.4rem;
  display: flex;
  flex-direction: column;
`;

const NoticeText = styled(Text)`
  &:first-line {
    line-height: 1.8rem;
  }
  line-height: 1.8rem;
`;

const StyledCaptionWysiwyg = styled(Wysiwyg)`
  margin: 0;
  margin-top: 1.5rem;

  ${Text} {
    font-family: 'adobe-caslon-pro', serif;
    font-style: normal;
    letter-spacing: 0.1rem;
    font-size: 1.3rem;
    line-height: 1.4rem;
    letter-spacing: 2px;
    font-variant: all-small-caps;
    text-align: center;
  }
`;