import React from "react";
import styled from "styled-components";

import { breakpoint } from "src/utils/media-query";

import { Quote } from "./Quote";

interface FeatureProps {
  content: ReadonlyArray<GatsbyTypes.Maybe<GatsbyTypes.StrapiVisitFeature>>;
}

export const Feature: React.FC<FeatureProps> = ({ content }) => (
  <Wrapper>
    <Divider />

    <QuotesWrapper>
      {content.map(quote => quote ? (
        <Quote
          key={quote.id}
          quote={quote.quote as string}
          attribution={quote.attribution}
        />
      ) : null)}
    </QuotesWrapper>

    <Divider />
  </Wrapper>
);

const Wrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0 1rem;

  ${breakpoint("tabletLL")} {
    margin: 4rem 0;
    padding: 0;
  }
`;

const Divider = styled.div`
  width: 50%;
  border-top: 1px solid ${({ theme }) => theme.colors.red};
`;

const QuotesWrapper = styled.div`
  
`;