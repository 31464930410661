import React from "react";
import styled from "styled-components";

import { Text } from "src/components/Text";
import { breakpoint } from "src/utils/media-query";

interface QuoteProps {
  quote: string;
  attribution?: string;
  wrapQuote?: boolean;
}

export const Quote: React.FC<QuoteProps> = ({
  quote,
  attribution,
  wrapQuote = true,
  ...props
}) => (
  <Wrapper {...props}>
    <QuoteText as="p" color="red">
      {wrapQuote ? `‘${quote}’` : quote}
    </QuoteText>

    <AttributionText as="p" color="red">
      {attribution}
    </AttributionText>
  </Wrapper>
);

const Wrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 2rem;

  &:last-child {
    margin-bottom: 2rem;
  }

  ${breakpoint("tabletLL")} {
    padding: 0 1rem;
    margin-top: 4rem;

    &:last-child {
      margin-bottom: 4rem;
    }
  }
`;

const QuoteText = styled(Text)`
  font-style: italic;
  font-size: 1.6rem;
  line-height: 2.4rem;

  ${breakpoint("tabletSP")} {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;

const AttributionText = styled(Text)`
  font-size: 1.3rem;
  line-height: 2rem;

  ${breakpoint("tabletSP")} {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
`;