import React from "react";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

import { BodyText } from "src/components/BodyText";
import { PageLayout } from "src/components/layout/PageLayout";
import { getImageMetadata } from "src/utils/image";
import { PageContent } from "src/components/layout/PageContent";
import { Feature } from "src/components/visit/Feature";
import { DynamicPageContent } from "src/components/visit/DynamicPageContent";
import { breakpoint } from "src/utils/media-query";
import { Wysiwyg } from "src/components/Wysiwyg";
import { Text } from "src/components/Text";
import { Quote } from "src/components/visit/Quote";

interface AboutPage extends PageProps {
  data: GatsbyTypes.AboutPageQuery;
}

const AboutPage: React.FC<AboutPage> = ({ data }) => {
  const pageTitle = data.strapiVisit?.title;
  const showTitle = data.strapiVisit?.titleVisible;
  const banner = data.strapiVisit?.banner;
  const heading = data.strapiVisit?.heading_quote;
  const [ aboutImage, aboutImageAlt ] = getImageMetadata(data.strapiVisit?.cover_image?.image);
  const aboutImageCaption = data.strapiVisit?.cover_image?.caption;
  const introduction = data.strapiVisit?.introduction;
  const feature = data.strapiVisit?.feature;
  const dynamicContent = data.strapiVisit?.page_content;

  return (
    <PageLayout title={pageTitle || "Visit"} width="full">
      {showTitle && pageTitle && (
        <TitleWrapper>
          <Text as="h1" uppercase>
            {pageTitle}
          </Text>
        </TitleWrapper>
      )}
      {heading && heading.quote && (
        <HeadingQuote
          quote={heading.quote}
          attribution={heading.attribution}
          wrapQuote={false}
        />
      )}
      {(!!banner?.enabled && !!banner.content) && (
        <Banner>
          <StyledWysiwyg content={banner.content} />
        </Banner>
      )}
      {aboutImage && (
        <RelativeWrapper>
          <AbsoluteWrapper>
            <StyledImage
              image={aboutImage}
              alt={aboutImageAlt}
            />
          </AbsoluteWrapper>
        </RelativeWrapper>
      )}
      {aboutImageCaption && (
        <StyledPageContent width="wide">
          <StyledCaptionWysiwyg content={aboutImageCaption} />
        </StyledPageContent>
      )}
      {introduction && (
        <StyledPageContent width="normal">
          <IntroWrapper>
            <BodyText text={introduction} />
          </IntroWrapper>
        </StyledPageContent>
      )}
      {(feature && feature.length > 0) && (
        <StyledPageContent width="normal">
          <Feature content={feature} />
        </StyledPageContent>
      )}
      {dynamicContent && (
        <DynamicPageContent content={dynamicContent} width="full" />
      )}
    </PageLayout>
  );
};

export default AboutPage;

const Banner = styled.div`
  box-sizing: border-box;
  padding: 1.5rem 2.8rem;
  padding-top: 2rem;
  background-color: ${({ theme }) => theme.colors.white}ce;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  margin-bottom: 2rem;
`;

const StyledWysiwyg = styled(Wysiwyg)`
  margin: 0;

  ${Text} {
    font-family: 'adobe-caslon-pro', serif;
    font-style: normal;
    line-height: 1.6rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    font-size: 1rem;
    text-align: center;
  }
`;

const HeadingQuote = styled(Quote)`
  margin-top: 0;
  margin-bottom: 2rem;
`;

const StyledCaptionWysiwyg = styled(Wysiwyg)`
  margin: 0;

  ${Text} {
    font-family: 'adobe-caslon-pro', serif;
    font-style: normal;
    letter-spacing: 0.1rem;
    font-size: 1.3rem;
    line-height: 1.4rem;
    letter-spacing: 2px;
    font-variant: all-small-caps;
    text-align: center;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3rem;
  padding-bottom: 2.4rem;

  ${breakpoint("tabletLL")} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const RelativeWrapper = styled.div`
  position: relative;
  padding-top: 100%;

  ${breakpoint("tabletLL")} {
    padding-top: 50%;
  }
`;

const AbsoluteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const StyledImage = styled(GatsbyImage)`
  flex: 1;
`;

const StyledPageContent = styled(PageContent)`
  padding-top: 1.5rem;
  padding-bottom: 0;
`;

const IntroWrapper = styled.div`
  text-align: center;
  
  margin-top: 2rem;
  padding: 1rem;

  ${breakpoint("tabletLL")} {
    margin-top: 4rem;
    padding: 0;
  }
`;

export const query = graphql`
  query AboutPage ($id: String!) {
    strapiVisit(id: { eq: $id }) {
      title
      titleVisible
      banner {
        enabled
        content
      }
      heading_quote {
        quote
        attribution
      }
      cover_image {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        caption
      }
      introduction
      feature {
        id
        quote
        attribution
      }
      page_content {
        id
        strapi_component
        title
        subtitle
        description
        body_text
        quote
        page_width_image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        image_left {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        image_right {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        caption
        notice_text
      }
    }
  }
`;
